import { css } from "@emotion/react";
import { memo } from "react";
export const PocketBrokerEventLink = memo(function PocketBrokerEventLink() {
    return (<a href={'https://events.pocket.tw/20435-30017'} target='_blank' rel='noreferrer' css={css `
        height: 38px;
      `}>
      <img src='lung88988/news.png' css={css `
          height: 100%;
          border-radius: 2px;
        `}/>
    </a>);
});
